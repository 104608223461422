import React from "react";
import SidebarItem from "./SidebarItem";
import {library} from '@fortawesome/fontawesome-svg-core';

import * as Icons from '@fortawesome/free-solid-svg-icons';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';

import "../../styles/main.css";
import testLogo from '../../assets/Test.png';
import logo_light from '../../assets/header/logo_light.png';
import logo_dark from '../../assets/header/logo_dark.svg';
import Colors from "../../library/Colors";
import SftGlobalData from "../../library/SftGlobalData";
import { useNavigate } from "react-router-dom";

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import StoreIcon from '@mui/icons-material/Store';
import AddIcon from '@mui/icons-material/Add';
import UpdateIcon from '@mui/icons-material/Update';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import LineChartIcon from '@mui/icons-material/ShowChart';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import KeyIcon from '@mui/icons-material/Key';
import crypto from 'crypto-js';

let sftDb = {
    "id": "179",
    "db_id": "179",
    "client_id": "255",
    "user_id": "1001",
    "description": "SoftTouch-Vlaanderen leeuw",
    "is_active": "1",
    "is_owner": "1",
    "has_owner": 1,
    "settings": {
        "modules": [
            {
                "group": "web",
                "visible": 1,
                "groupData": [
                    {
                        "name": "Integrations",
                        "active": 1,
                        "module": "integration"
                    },
                    {
                        "name": "Shipping",
                        "active": 1,
                        "module": "shipping"
                    }
                ]
            },
            {
                "group": "admin",
                "visible": 1,
                "groupData": [
                    {
                        "name": "Licence module",
                        "active": 1,
                        "module": "licence"
                    },
                    {
                        "name": "Birthday SMS",
                        "active": 1,
                        "module": "birthday"
                    },
                    {
                        "name": "Mailchimp",
                        "active": 1,
                        "module": "mailchimp"
                    },
                    {
                        "name": "User management",
                        "active": 1,
                        "module": "users"
                    }
                ]
            },
            {
                "group": "other",
                "visible": 1,
                "groupData": [
                    {
                        "name": "MyFasMan Mobile",
                        "active": 1,
                        "module": "mobile"
                    },
                    {
                        "name": "E-ticket",
                        "active": 1,
                        "module": "eticket"
                    },
                    {
                        "name": "Excel",
                        "active": 1,
                        "module": "excel"
                    }
                ]
            }
        ]
    },
    "created_at": "2022-03-22 16:24:19",
    "updated_at": "2024-06-20 09:51:02",
    "user_create": "1001",
    "user_modify": "1001",
    "logo": "https://logos.softtouch.eu/logo_179.jpeg",
    "is_sft": false,
    "sft_cust": "",
    "encrypted_key": "206F9AE90F041298975BD8B4770C6868E50EAD4B2DB5E54F3C03794225FC561DE6E0484C010A78E776D1CF9C71AD7E6D8200EF21E35BB91A53C139F1E91E04B98A34EB62EC0C9C31611D6ED5DA532112F1217F9A5DFEAA5F29A63DC1FFB2C26FBA66A2BDFBDEEB4FAA1B14BCB9A428861897A43874B5629E803A6216569F778773A56DB83656069EC96705F6285C0FB0BFC5ECE315A7A6D6A1D62350AAEDE9E28031648AFDB5333AB8D2CD35DEE2A452C91D7A16EC48A3A1BCFB4C4F0CB10E1B55ADD87900152BEBCE6A3116C8CCDC87751CF46AD897DF17691A5328F498A1F510150C6EE03FEC0743CA2F0B7314B68734775DFC7524D4A5CF56E99D55E48FD0",
    "api_id": "-",
    "api_token": "-",
    "cust_no": "1276",
    "cust_url": "",
    "alt_name": "SoftTouch-Vlaanderen BV",
    "search_string": "",
    "cust_is_test": "0",
    "cust_is_local": "0",
    "cust_zone": "VL",
    "debug": ""
}
let testofzo = 0;
const LeftSidebar = (props) => {
  const loggingModules = 
  props.sft_stat === "1" ? 
    ((props.group === "ADMIN" || props.group === "SUPER") ?
        [{name: "Add"}, {name: "rma"}, {name: "Logs"}, {name: "Customers"}, {name: "AdminStats"}, {name: "Manuals"}, {name: "Templates"}, {name: "Connections"}, {name: "User-info"}, {name: "Updates"}] 
        : [{name: "Add"}, {name: "rma"}, {name: "Logs"}, {name: "Customers"}, {name: "AdminStats"}, {name: "Manuals"}, {name: "User-info"}, {name: "Updates"}])
    : (props.sft_stat === "2" ? 
        [{name: "Add"}, {name: "rma"}, {name: "Logs"}, {name: "Customers"}, {name: "AdminStats"}, {name: "Manuals"}, {name: "User-info"}, {name: "Updates"}] : 
        (props.sft_stat === "3" ? [{name: "Add"}, {name: "Logs"}, {name: "Customers"}, {name: "AdminStats"}, {name: "User-info"}, {name: "Updates"}] 
        : [])
      );
  let navigate = useNavigate();

  if(testofzo === 7){
    SftGlobalData.easter1 = true;
    //console.log("easter1", SftGlobalData.easter1);
  } else {
    SftGlobalData.easter1 = false;
  }
  
  //console.log("modules", props.modules);
  //---------------------------------------------------------------------------------------------
  //    FUNCTIONS
  //---------------------------------------------------------------------------------------------
  
  const handleNavigation = (route) => {
    const loggingRoutes = ["Logs", "Add", "Customers"];
    if(route === "rma"){
        props.selectDb(sftDb, "206F9AE90F041298975BD8B4770C6868E50EAD4B2DB5E54F3C03794225FC561DE6E0484C010A78E776D1CF9C71AD7E6D8200EF21E35BB91A53C139F1E91E04B98A34EB62EC0C9C31611D6ED5DA532112F1217F9A5DFEAA5F29A63DC1FFB2C26FBA66A2BDFBDEEB4FAA1B14BCB9A428861897A43874B5629E803A6216569F778773A56DB83656069EC96705F6285C0FB0BFC5ECE315A7A6D6A1D62350AAEDE9E28031648AFDB5333AB8D2CD35DEE2A452C91D7A16EC48A3A1BCFB4C4F0CB10E1B55ADD87900152BEBCE6A3116C8CCDC87751CF46AD897DF17691A5328F498A1F510150C6EE03FEC0743CA2F0B7314B68734775DFC7524D4A5CF56E99D55E48FD0");
    }
    if(loggingRoutes.includes(route)){
        if(props.screenWidth < 900){
          props.toggleMobileOpen();
        }
        navigate("/"+props.lang+"/logging/"+route.toLowerCase());
    } else {
        if(route.toLowerCase() === "updates"){         
            window.open('https://changelog.softtouch.eu/' + props.lang + '/mysft/' + crypto.AES.encrypt(JSON.stringify({is_valid: props.login.is_valid, sft_group: props.login.sft_group, sft_status: props.login.sft_status, sft_user_id: props.login.sft_user_id}), 'Soft295Touch!').toString().replaceAll("/", "DIRTY_SLASH_FIX"), '_blank');
        } else {
            navigate("/"+props.lang+"/"+route.toLowerCase());            
        }
    }
    
  }

  const checkPath = (route) => {
    const loggingRoutes = ["Logs", "Add", "Customers"];
    if(loggingRoutes.includes(route)){
      return "logging/"+route.toLowerCase();        
    } else {
        return route.toLowerCase();
    }
    
  }

  const getLogModules = loggingModules.map(function(data) {
        if(SftGlobalData.debug){
            console.log("data: ", data);
        }

        return (
            <ListItem /*className={'sidebaritem_item_' + props.theme}*/key={data.name} button onClick={()=>{handleNavigation(data.name)}}
                sx={{
                    borderRadius: 1,
                    mt: 3,
                    height: 50,
                    maxHeight: 60,
                    borderRight: (props.pathname === checkPath(data.name) || (data.name.toLowerCase() === "logs" && props.pathname === "logging")) ? 5 : 0,
                    color:
                    (props.pathname === checkPath(data.name) || (data.name.toLowerCase() === "logs" && props.pathname === "logging"))
                        ? '#28A9E0'
                        : '#969FA9',
                }}>
                {<ListItemIcon className={'sidebaritem_item__img '} 
                    sx={{
                      minWidth:30,
                      color: (props.pathname === checkPath(data.name) || (data.name.toLowerCase() === "logs" && props.pathname === "logging")) ? '#28A9E0': '#969FA9',
                    }}>
                    {data.name==="Logs" && <SupportAgentIcon />}
                    {data.name==="Add" && <AddIcon />}
                    {data.name==="Customers" && <StoreIcon />}
                    {data.name==="Connections" && <KeyIcon />}
                    {data.name==="Statistics" && <LineChartIcon />}
                    {data.name==="AdminStats" && <EqualizerIcon />}
                    {data.name==="Manuals" && <LibraryBooksIcon />}
                    {data.name==="Templates" && <FolderOpenIcon />}
                    {data.name==="Updates" && <UpdateIcon />}
                    {data.name==="User-info" && <ManageAccountsIcon />}
                    {(props.is_sft && data.name==="rma") && <BrokenImageIcon />}

                    {/*<img src={modIcon} width="30px" alt={props.data.module}/>*/}
                </ListItemIcon>}
            </ListItem>
        );
});

  const getModules = props.modules
  /*.sort(function(a, b) {
    if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return 0;
   })*/
  .map(function(data, idx) {
        if(SftGlobalData.debug){
            console.log("data.groupData: ", data);
        }

        const groupData = data.groupData.filter(group => group.active === 1);

        const subGroupItems = groupData.sort(function(a, b) {
            if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
            return 0;
        }).map(function(groupData, idy){
            if(groupData.active === 1){
                return (            
                    <SidebarItem className={props.theme} key={idy} title={groupData.name}  lang={props.lang} image={testLogo} isExpanded={props.menuExpanded} toggleMobileOpen={()=>{props.toggleMobileOpen()}} data={groupData} theme={props.theme}/>
                );
            } else {
                return (
                    <div key={idy}>
                    </div>
                );
            }
        });


        /*if(data.groupData.length === 0 || data.visible == 0){
            return (
                <div key={idx}></div>
            );
        } else {*/
        //console.log("props", props);
            //console.log('length', data.group + ' ' +groupData.length);
            return (
                <div className={'sidebar_header ' + props.theme + (testofzo === 7 ? " pink" : "")} key={idx}>
                    {groupData?.length > 0 && <h3 className='sidebar_header_item' >{data.group}</h3>}
                    {subGroupItems}
                    {(data.group==="other" && props.screen !== "logging" && props.db?.is_owner && !props.is_sft) && <SidebarItem className={props.theme} key={"eticket"} title={"E-ticket"}  lang={props.lang} isExpanded={props.menuExpanded} toggleMobileOpen={()=>{props.toggleMobileOpen()}} data={{module: "eticket"}} theme={props.theme}/>}
                    {(data.group==="other" && props.screen !== "logging" && props.group === "SUPER") && <SidebarItem className={props.theme} key={"EXACT"} title={"Exact"}  lang={props.lang} isExpanded={props.menuExpanded} toggleMobileOpen={()=>{props.toggleMobileOpen()}} data={{module: "exact"}} theme={props.theme}/>}
                    {(data.group==="other" && props.screen !== "logging" && props.is_sft) && <SidebarItem className={props.theme} key={"RMA"} title={"RMA"}  lang={props.lang} isExpanded={props.menuExpanded} toggleMobileOpen={()=>{props.toggleMobileOpen()}} data={{module: "rma"}} theme={props.theme}/>}
                </div>
            );
        //}
});

  const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

  library.add(...iconList);

  /*const handleToggler = () => {
    props.setMenuExpanded(!props.menuExpanded);
    if(SftGlobalData.debug){
        console.log('sideBarCollapsed', props.menuExpanded);
    }
    localStorage.setItem('left_sidebar-collapsed', props.menuExpanded);

  }*/

  return (
    <Box className={props.theme+"_accent "} sx={{ display: 'flex' }}>
      <CssBaseline className={props.theme+"_accent "} />
      <Box
        className={props.theme+"_accent "}
        component="nav"
        sx={{ width: { sm: props.drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          className={props.theme}
          variant="temporary"
          open={props.mobileOpen}
          onClose={()=>{props.toggleMobileOpen()}}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.drawerWidth, backgroundColor: (props.theme === 'dark' ? Colors.dark_basic : Colors.light_basic) },
          }}
        >
            {props.screen !== "logging" && getModules}
            {props.screen === "logging" && <div className={'sidebar_header ' + props.theme + (testofzo === 7 ? " pink" : "")}> {getLogModules} </div>}
        </Drawer>
        <Drawer
          className={props.theme}
          variant="permanent"  
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.drawerWidth, backgroundColor: (props.theme === 'dark' ? Colors.dark_basic : Colors.light_basic) },
          }}
          open
        >
        <div className={props.theme + " sidebar-left"}>
            <div className={"logo _100perc "} onClick={()=>{if(SftGlobalData.easter2){testofzo++;}}} style={{height: 10}}>
                {props.screen !== "logging" && <img alt="SoftTouch Logo" src={(props.theme==='dark') ? logo_dark : logo_light} className={'header__logo '} onClick={()=>{if(props.is_sft){navigate("/"+props.lang+"/environment");}}}/>}
            </div>
            <div className="sidebar_items">
                {props.screen !== "logging" && getModules}
                {props.screen === "logging" && <div className={'sidebar_header ' + props.theme + (testofzo === 7 ? " pink" : "")}> {getLogModules} </div>}
            </div>
        </div>
        </Drawer>
      </Box>
    </Box>
  );
};

export default LeftSidebar;