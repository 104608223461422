import React, { useEffect, useState } from 'react';
import { TailSpin } from  'react-loader-spinner';
import { 
    Link,
    useNavigate,
    useParams
} from "react-router-dom";
import { TextField, Button, FormControl, InputLabel, MenuItem, Select, ListItemText, Switch } from '@mui/material';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import SftGlobalData from '../library/SftGlobalData';
import ReactTooltip from 'react-tooltip';
import Colors from '../library/Colors';
import FloatingBackButton from '../components/FloatingBackButton';
import FloatingSaveButton from '../components/FloatingSaveButton';

const IntegrationDetailScreen = (props) =>  {
    const params = useParams();
    const [loading, setLoading] = useState(params.listId !== '+');

    const [listId, setListId] = useState(params.listId);
    const [photoUrl, setPhotoUrl] = useState("");
    const [articleUrl, setArticleUrl] = useState("");
    const [title, setTitle] = useState("[code2][levart2][lev_naam]");
    const [description, setDescription] = useState("[code2][kleurlev]");
    const [code1, setCode1] = useState(false);
    const [code2, setCode2] = useState(true);
    const [code3, setCode3] = useState(false);
    const [code4, setCode4] = useState(false);
    const [code5, setCode5] = useState(false);
    const [code6, setCode6] = useState(false);
    const [code7, setCode7] = useState(false);
    const [online, setOnline] = useState(1);
    const onlineOptions = [0,1,2,3,4,5];
    const [stores, setStores] = useState("");
    const [availableStores, setAvailableStores] = useState("");
    const [folder, setFolder] = useState("");
    const [genderCode, setGenderCode] = useState("");
    const [gender, setGender] = useState("unisex");
    const [male, setMale] = useState([]);
    const [female, setFemale] = useState([]);
    const [unisex, setUnisex] = useState([]);

    const [ageGroupCodes, setAgeGroupCodes] = useState([]);
    const [genderGroupCodes, setGenderGroupCodes] = useState([]);
    
    const [ageGroup, setAgeGroup] = useState("adult");
    const [ageCode, setAgeCode] = useState("");
    const [ageAdult, setAgeAdult] = useState([]);
    const [ageTeen, setAgeTeen] = useState([]);
    const [ageKids, setAgeKids] = useState([]);
    const [ageNewborn, setAgeNewborn] = useState([]);
    const [ageInfant, setAgeInfant] = useState([]);

    const [repId, setRepId] = useState(0);
    const [generateRepId, setGenerateRepId] = useState("");
    const [active, setActive] = useState(false);
    const [database, setDatabase] = useState("");

    const [freeShippingFrom, setFreeShippingFrom] = useState("");
    const [chkIncreasedShipping, setChkIncreasedShipping] = useState(false);
    const [increasedShipping, setIncreasedShipping] = useState("");
    const [increasedShippingFrom, setIncreasedShippingFrom] = useState("");
    const [customWhere, setCustomWhere] = useState("");

    //Currently only used for channable integrations
    const [integrationToken, setIntegrationToken] = useState("");
    const [integrationProjectId, setIntegrationProjectId] = useState("");
    const [integrationCompanyId, setIntegrationCompanyId] = useState("");
    
    let navigate = useNavigate();

    const location = useLocation();

    const { list: listPassed, database: db } = location.state || {};
 
    // Initialize state with passed data or fetch based on params
    const [list, setList] = useState(listPassed || null);

    useEffect(async () => {
        
        const setFields = (dataList) => {
            setList(dataList);
            if(props.integration === "channable"){
                setIntegrationToken(dataList.token);
                setIntegrationProjectId(dataList.free1);
                setIntegrationCompanyId(dataList.free2);
            }
            try{
                setList(dataList);
                setFolder(dataList.config.foldername);
                setActive(dataList.active === "Y");
                if(dataList.config.repid != 0 && dataList.config.repid){
                    setRepId(dataList.config.repid ? parseInt(dataList.config.repid) : 0);
                } else {
                    setPhotoUrl(dataList.config.url ?? "");
                    setArticleUrl(dataList.config.articleUrl ?? "");
                    setTitle(dataList.config.title ?? "");
                    setDescription(dataList.config.description ?? "");
                    setCode1(dataList.config.code1 ?? false);
                    setCode2(dataList.config.code2 ?? false);
                    setCode3(dataList.config.code3 ?? false);
                    setCode4(dataList.config.code4 ?? false);
                    setCode5(dataList.config.code5 ?? false);
                    setCode6(dataList.config.code6 ?? false);
                    setCode7(dataList.config.code7 ?? false);
                    setOnline(dataList.config.online ?? "");
                    setStores(dataList.config.stores ?? "");
                    setGender(dataList.config.gender ?? "");
                    setAgeGroup(dataList.config.age ?? "");
                    if(dataList.config.age){ fetchCodes( codes?.find(code => code.id ===  dataList.config.age)?.code, "age")}
                    setMale(dataList.config.male ? dataList.config.male?.split(",") : []);
                    setFemale(dataList.config.female ? dataList.config.female?.split(",") : []);
                    setUnisex(dataList.config.unisex ? dataList.config.unisex?.split(",") : []);
                    setGenderCode(dataList.config.genderCode ?? "");
                    if(dataList.config.genderCode){ fetchCodes( codes?.find(code => code.id ===  dataList.config.genderCode)?.code, "gender")}
                    setAgeAdult(dataList.config.adult ?  dataList.config.adult?.split(",") : []);
                    setAgeTeen(dataList.config.teen ? dataList.config.teen?.split(",") : []);
                    setAgeKids(dataList.config.kids ? dataList.config.kids?.split(",") : []);
                    setAgeInfant(dataList.config.infant ? dataList.config.infant?.split(",") : []);
                    setAgeNewborn(dataList.config.newborn ? dataList.config.newborn?.split(",") : []);
                    setAgeCode(dataList.config.ageCode ?? "");
                    setGenerateRepId(dataList.config.generateRepId ?? "");

                    setArticlesLimit(dataList.config.articlesLimit ?? "");
                    setFreeShippingFrom(dataList.config.freeShippingFrom ?? "");
                    setChkIncreasedShipping((dataList.config.increasedShipping || dataList.config.increasedShipping) ? true : false);
                    setIncreasedShipping(dataList.config.increasedShipping ?? 0);
                    setIncreasedShippingFrom(dataList.config.increasedShippingFrom ?? 0);
                    setCustomWhere(dataList.config.customWhere ?? "");
                    setEdiArticlesOnly(dataList.config.ediArticlesOnly ?? false);
                }  
                   
            }catch(e){
                if(SftGlobalData.debug){
                    console.log('ERROR', e);
                }
            }
            setLoading(false);
        };

        props.changeScreen('Licence');
        fetchStores();
        if(db){
            setDatabase(db);
        }
        if (listPassed) {
            setFields(listPassed);
        }
    }, [listPassed, db, params.listId, props.api]);

    const fetchStores = () => {
        let url = SftGlobalData.baseURL_API + 'sftData';
        axios.post(url, {key: props.api, type: "stores"}, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('response stores', response);
            }
            setAvailableStores(response.data.data);
        });
    }

    const fetchCodes = (selectedCode, type) => {
        selectedCode = selectedCode ? selectedCode : '01';
        const url = SftGlobalData.baseURL_API + 'sftData';
        axios.post(url, {key: props.api, type: "codes",code: selectedCode}, {
            headers : {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }})
        .then(response => {
            if(SftGlobalData.debug){
                console.log('url', url);
                console.log('response getCode 01' , response);
            }
            let codes = response.data?.data?.sort((a, b) => a.code - b.code);
            switch(type){
                case "gender":
                    setGenderGroupCodes(codes);
                    break;
                case "age":
                    setAgeGroupCodes(codes);
                    break;
                default:
                    break;
            }
        });
    }

    const clearSelectedGenderCodes = () => {
        integrationGenderCodes?.map(code => {
            return code.onChange([]);
        });
    }

    const clearSelectedAgeCodes = () => {
       integrationCodes?.[0]?.[props.integration]?.map(code => {
            return code.onChange([]);
        });
    }

    const getIntegrationFileUrl = () => {
        let fileName = "";
        let url = "";
        let debugUrl = (SftGlobalData.debug) ? "shopping2.softtouch.eu/" : "";
            
        switch(props.integration){
            case "facebook": 
                url = SftGlobalData.baseURL_shopping + debugUrl + "facebook/upload/files/";
                fileName = "FBS.tsv";
                break;
            case "google":
                url = SftGlobalData.baseURL_shopping + debugUrl + "upload/files/"
                fileName = "GM.tsv";
                break;
            case "channable":
                url = SftGlobalData.baseURL_shopping + debugUrl  + "channable/upload/files/"
                fileName = "CHN.tsv";
                break;
            default:
                url = SftGlobalData.baseURL_shopping + debugUrl + "facebook/upload/files/";
                fileName = "FBS.tsv";
                break;
        }

        if(folder === "" || (typeof folder === "undefined") || !folder){
            url += database + "/" + fileName;
        } else {
            url += folder + "/" + fileName;
        }

        return url;
    
    }

    const validateCustomWhereInput = (value) => {
        const regex = /^[a-zA-Z0-9\s=><!_"']+$/;
        if (!regex.test(value) && value !== "") {
            
            return false;
        }
        setCustomWhere(value);
        return true;
    };

    const addToBody = (text, field) => {
        if(field === 'title'){
            setTitle(title+text);
        } else if(field === 'description'){
            setDescription(description+text);
        }
    }

    const fetchIntegrationData = async (listId) => {
        try {
            let url = `${SftGlobalData.baseURL_API}integrations&action=overview&type=${props.integration}`;
            let postData = { key: props.api };
            const response = await axios.post(url, postData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
            });
            if (SftGlobalData.debug) {
                console.log('url', url);
                console.log('postData', postData);
                console.log('resp new', response);
            }
            let filteredList = response.data.lists.find(list => list.id == listId);
            navigate(`/${props.lang}/${props.integration}-detail/${listId}`, { state: { list: filteredList, database: db } });
        } catch (e) {
            console.error(e);
        }
    };

    const createOrUpdateReport = async () => {
        let APIurl = `${SftGlobalData.baseURL_API}integrations&action=report&type=${props.integration}&id=${listId}`;
        let postData = { 
            key: props.api,
            Data: {
            ageCode: ageCode, adult: ageAdult?.join(","), teen: ageTeen?.join(","), kids: ageKids?.join(","), infant: ageInfant?.join(","), newborn: ageNewborn?.join(","), genderCode: genderCode, 
            unisex: unisex?.join(","), male: male?.join(","), female: female?.join(","), active: (active ? "Y" : "N"), repid: repId, online: online, foldername: folder, stores: stores, url: photoUrl, articleUrl: articleUrl, title: title, 
            description: description, gender: gender, age: ageGroup, code1: code1, code2: code2, code3: code3, code4: code4, code5: code5, code6: code6, code7: code7, generateRepId: generateRepId,
            freeShippingFrom: freeShippingFrom, increasedShipping: increasedShipping, increasedShippingFrom: increasedShippingFrom, articlesLimit: articlesLimit, customWhere: customWhere, ediArticlesOnly: ediArticlesOnly
        }};
        const response = await axios.post(APIurl, postData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
        });
        if(SftGlobalData.debug){
            console.log('url', APIurl);
            console.log('response', response);
        }
        if(response.data !== "ERROR"){
            props.notify(props.t('export_rep_created') + ": " + response.data.repId, 7500, "top-right", props.theme, 1);
            fetchIntegrationData(listId);
        } else { 
            props.notify(props.t('errorOccured'), 7500, "top-right", props.theme, 1); 
        }
    }

    const createOrUpdateIntegration = async (actionType, id=null) => {
        try {
            let update = actionType == "Create" ? "" : `&id=${id}`;
            let APIurl = `${SftGlobalData.baseURL_API}integrations&action=integration&type=${props.integration}${update}`;
            let postData = {
                key: props.api,
                data: {
                    ageCode: ageCode, adult: ageAdult?.join(","), teen: ageTeen?.join(","), kids: ageKids?.join(","), infant: ageInfant?.join(","), newborn: ageNewborn?.join(","), genderCode: genderCode, 
                    unisex: unisex?.join(","), male: male?.join(","), female: female?.join(","), active: (active ? "Y" : "N"), repid: repId, online: online, foldername: folder, stores: stores, url: photoUrl, articleUrl: articleUrl, title: title, 
                    description: description, gender: gender, age: ageGroup, code1: code1, code2: code2, code3: code3, code4: code4, code5: code5, code6: code6, code7: code7, generateRepId: generateRepId,
                    freeShippingFrom: freeShippingFrom, increasedShipping: increasedShipping, increasedShippingFrom: increasedShippingFrom, articlesLimit: articlesLimit, customWhere: customWhere, ediArticlesOnly: ediArticlesOnly
                },
            };
            if(props.integration == "channable"){
                postData.channable = {
                    token: integrationToken,
                    free1: integrationCompanyId,
                    free2: integrationProjectId,
                }
            }
            const response = await axios.post(APIurl, postData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
            });
            if (SftGlobalData.debug) {
                console.log('photoUrl', photoUrl);
                console.log('response', response);
            }

            if(!response?.error){
                setListId(response.data.list);
                (generateRepId) ? createOrUpdateReport() : fetchIntegrationData(response.data.list);
                props.notify(props.t('savedChanges'), 7500, "top-right", props.theme, 1); 
            } else { 
                props.notify(props.t('errorOccured'), 7500, "top-right", props.theme, 1); 
            }
        } catch (e) {
            console.error(e);
        }
    };
    
    const handleSubmit = () => {
        if((!repId || repId == "0") && (!online || !photoUrl || !ageGroup || !title || !description || !gender)){
            props.notify(props.t('missing_fields'), 7500, "top-right", props.theme, 1); 
        } else {
            (listId === '+') ? createOrUpdateIntegration('Create') : createOrUpdateIntegration('Update',listId);
        }
    };

    function showSettings(integrationType) {
    
        return ( 
            <div>
                <TextField
                autoComplete="off" 
                className={props.theme+"_accent "+props.theme+"_text _100perc"}                
                style={{width: '49%', margin: "0.3rem"}}
                id="integration_folder"
                label={props.t('integration_folder')}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                    },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                    }} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }}}
                value={folder}
                onChange={(event)=>{setFolder(event.target.value)}}
                />

                <TextField
                autoComplete="off" 
                 className={props.theme+"_accent "+props.theme+"_text _100perc"} 
                style={{width: '49%', margin: "0.3rem"}}
                id="integration_folder"
                label={props.t('rep_id')}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                    },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                    }} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }}}
                value={repId}
                onChange={(event)=>{setRepId(event.target.value)}}
                />

            { 
                (integrationType === "channable") &&
                <>
                
                    <TextField
                    autoComplete="off" 
                    className={props.theme+"_accent "+props.theme+"_text _100perc"}                
                    style={{width: '49%', margin: "0.3rem"}}
                    id="channable_token"
                    label={props.t('channable_token')}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text
                        }} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }}}
                    value={integrationToken}
                    onChange={(event)=>{setIntegrationToken(event.target.value)}}
                    />

                    <TextField
                    autoComplete="off" 
                    className={props.theme+"_accent "+props.theme+"_text _100perc"} 
                    style={{width: '24.15%', margin: "0.3rem"}}
                    id="channable_project_id"
                    label={props.t('channable_project_id')}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text
                        }} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }}}
                    value={integrationProjectId}
                    onChange={(event)=>{setIntegrationProjectId(event.target.value)}}
                    />

                    <TextField
                    autoComplete="off" 
                    className={props.theme+"_accent "+props.theme+"_text _100perc"} 
                    style={{width: '24.15%', margin: "0.3rem"}}
                    id="channable_company_id"
                    label={props.t('channable_company_id')}
                    sx={(props.theme==='light') ? {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.light_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.light_text
                        }} 
                        :
                        {
                        '& .MuiOutlinedInput-input': {
                            color: Colors.dark_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.dark_text_accent
                            }}}
                    value={integrationCompanyId}
                    onChange={(event)=>{setIntegrationCompanyId(event.target.value)}}
                    />

                </>
            }
            </div>
        )
    }

    function showPersonalization() {
        return ( 
            <div>
                <FormControl style={{ width: '49%', margin: "0.3rem" }}>
                <InputLabel id="demo-simple-select-label-art">
                    {props.t('online')}:*
                </InputLabel>
                <Select
                    id="integration_online"
                    className={props.theme+"_accent "+props.theme+"_text _100perc"}
                    labelId="demo-simple-select-label-art"
                    label={props.t('integration_online')}
                    value={online}
                    onChange={(e) => setOnline(Number(e.target.value))}
                    style={{ maxHeight: 43 }}
                    renderValue={(selected) => selected.toString()}
                >
                    {onlineOptions.map((row, index) => (
                    <MenuItem value={row} key={index}>
                        <ListItemText primary={row.toString()} />
                    </MenuItem>
                    ))}
                </Select>
                </FormControl>

                {availableStores &&   <FormControl style={{ width: '49%', margin: "0.3rem" }}>
                    <InputLabel id="demo-simple-select-label-art">{props.t('integration_stores')}:</InputLabel>
                    <Select id="integration_stores"
                    className={props.theme+"_accent "+props.theme+"_text _100perc"}
                    labelId="demo-simple-select-label-art" 
                    label={props.t('integration_stores')} 
                    value={stores} 
                    onChange={(e) => {setStores(e.target.value)}} 
                    style={{ maxHeight: 43 }}
                    renderValue={(selected) => {
                        if (!selected)
                            return " - ";
                        const selectedOption = availableStores.find(option => option.code == selected);
                        return selectedOption ? (selectedOption.code + ' - ' + selectedOption.oms) : '';
                    }}>
                        <MenuItem value={''} name={''} key={''}>
                            <ListItemText
                                primary={<span></span>}
                            />
                        </MenuItem>
                        {availableStores.map((row, index) => {
                            return (
                                <MenuItem value={row.code} name={row.code} key={index}>
                                    <ListItemText
                                        primary={<span>{row.code} - {row.oms}</span>}
                                    />
                                </MenuItem>)
                        })}
                    </Select>
                </FormControl>}

                <TextField style={{ width: '98.6%', margin: "0.3rem" }}
                autoComplete="off" 
                className={props.theme+"_accent "+props.theme+"_text _100perc"} 
                id="integration_photo_URL"
                label={props.t('integration_photo_URL')}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                    },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                    }} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }}}
                value={photoUrl}
                onChange={(event)=>{setPhotoUrl(event.target.value)}}
                />

                <TextField style={{ width: '98.6%', margin: "0.3rem" }}
                autoComplete="off" 
                className={props.theme+"_accent "+props.theme+"_text _100perc"} 
                id="integration_article_URL"
                label={props.t('integration_article_URL')}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                    },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                    }} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }}}
                value={articleUrl}
                onChange={(event)=>{setArticleUrl(event.target.value)}}
                />
            </div>
        )
    }

    const ageGroups = ['all_ages','adult','teen','kids','newborn'];

    const codes = [
        {id: 'code1', value: 'CODE 1', code: '01'},
        {id: 'code2', value: 'CODE 2', code: '02'},
        {id: 'code3', value: 'CODE 3', code: '03'},
        {id: 'code4', value: 'CODE 4', code: '04'},
        {id: 'code5', value: 'CODE 5', code: '05'},
        {id: 'code6', value: 'CODE 6', code: '06'},
        {id: 'code7', value: 'CODE 7', code: '07'}
    ];

    const integrationCodes = [
        {
            facebook: [
                {id: 'integration_adult', value: 'codes_adult', inputValue: ageAdult, onChange: setAgeAdult},
                {id: 'integration_teen', value: 'codes_teen', inputValue: ageTeen, onChange: setAgeTeen},
                {id: 'integration_kids', value: 'codes_kids', inputValue: ageKids, onChange: setAgeKids},
                {id: 'integration_newborn', value: 'codes_newborn', inputValue: ageNewborn, onChange: setAgeNewborn}
            ],
            google: [
                {id: 'integration_adult', value: 'codes_adult_g', inputValue: ageAdult, onChange: setAgeAdult},
                {id: 'integration_teen', value: 'codes_teen_g', inputValue: ageTeen, onChange: setAgeTeen},
                {id: 'integration_kids', value: 'codes_kids_g', inputValue: ageKids, onChange: setAgeKids},
                {id: 'integration_infant', value: 'codes_infant', inputValue: ageInfant, onChange: setAgeInfant},
                {id: 'integration_newborn', value: 'codes_newborn_g', inputValue: ageNewborn, onChange: setAgeNewborn}
            ],
            channable: [
                {id: 'integration_adult', value: 'codes_adult_g', inputValue: ageAdult, onChange: setAgeAdult},
                {id: 'integration_teen', value: 'codes_teen_g', inputValue: ageTeen, onChange: setAgeTeen},
                {id: 'integration_kids', value: 'codes_kids_g', inputValue: ageKids, onChange: setAgeKids},
                {id: 'integration_infant', value: 'codes_infant', inputValue: ageInfant, onChange: setAgeInfant},
                {id: 'integration_newborn', value: 'codes_newborn_g', inputValue: ageNewborn, onChange: setAgeNewborn}
            ]
        }
    ];
    
    const integrationGenders = ['female','male','unisex'];

    const integrationGenderCodes = [
        {id: 'integration_female', value: 'integration_female_codes', inputValue: female, onChange: setFemale},
        {id: 'integration_male', value: 'integration_male_codes', inputValue: male, onChange: setMale},
        {id: 'integration_unisex', value: 'integration_unisex_codes', inputValue: unisex, onChange: setUnisex}
    ];

    function showIntegrationConfig(integrationType){
        return (
        <span>
            <div>
                <FormControl style={{ width: '49%', margin: "0.3rem" }}>
                    <InputLabel id="demo-simple-select-label-art">{props.t('integration_age')}:*</InputLabel>
                    <Select id="integration_gender"
                    className={props.theme+"_accent "+props.theme+"_text _100perc"}
                    labelId="demo-simple-select-label-art" 
                    label={props.t('integration_gender')} 
                    value={ageGroup} 
                    onChange={(e) => {setAgeGroup(e.target.value)}} 
                    style={{ maxHeight: 43 }}
                    renderValue={(selected) => props.t(selected)}>
                        {ageGroups.map((row, index) => {
                            return (
                                <MenuItem value={row} name={row} key={index}>
                                    <ListItemText
                                        primary={props.t(row)}
                                    />
                                </MenuItem>)
                        })}
                    </Select>
                </FormControl>
                <FormControl style={{ width: '49%', margin: "0.3rem" }}>
                    <InputLabel id="demo-simple-select-label-art">{props.t('integration_age')}:</InputLabel>
                    <Select id="integration_age"
                    className={props.theme+"_accent "+props.theme+"_text _100perc"}
                    labelId="demo-simple-select-label-art" 
                    label={props.t('integration_age')} 
                    value={ageCode} 
                    onChange={(e) => {
                        setAgeCode(e.target.value);
                        clearSelectedAgeCodes();
                        fetchCodes(codes?.find(code => code.id === e.target.value)?.code, "age");
                    }} 
                    style={{ maxHeight: 43 }}
                    renderValue={(selected) => {
                        if (!selected)
                            return "";
                        const selectedOption = codes.find(option => option.id == selected)?.value;
                        return selectedOption ?? '';
                    }}>
                    {codes.map((row, index) => {
                        return (
                            <MenuItem value={row.id} name={row.id} key={index}>
                                <ListItemText
                                    primary={props.t(row.value)}
                                />
                            </MenuItem>)
                    })}
                    </Select>
                </FormControl>

                {
        
                integrationCodes?.[0]?.[integrationType].map((row, index) => {

                    const getOtherSelectedValues = (currentRowId) => {
                        return [...new Set(
                            integrationCodes?.[0]?.[integrationType]
                                .filter(code => code.id !== currentRowId)
                                .map(code => code.inputValue)
                                .flat()
                        )];
                    };
                    let otherSelectedValues = getOtherSelectedValues(row.id);
                    return (
                        <FormControl key={row.id} style={{ width: '49%', margin: "0.3rem" }}>
                            <InputLabel id="demo-simple-select-label-art">{props.t(row.value)}</InputLabel>
                            <Select  id={row.id}
                            className={props.theme+"_accent "+props.theme+"_text _100perc"}
                            labelId="demo-simple-select-label-art" 
                            label={props.t(row.value)}
                            value={row.inputValue}
                            disabled={!ageCode}
                            onChange={(event)=>{row.onChange(event.target.value)}}
                            style={{ maxHeight: 43 }}
                            multiple
                            renderValue={(selected) => {
                                if (!selected)
                                    return "";
                                return selected?.sort((a, b) => a.localeCompare(b))?.join(",");
                            }}>
                                {ageGroupCodes?.filter(ageGroupCode => {
                                    return !otherSelectedValues?.includes(ageGroupCode?.totcode);
                                })?.map((row, index) => {
                                    return (
                                        <MenuItem value={row.totcode} name={row.codeoms} key={index}>
                                            <ListItemText
                                                primary={props.t(row.totcode)}
                                                secondary={props.t(row.codeoms)}
                                            />
                                        </MenuItem>)
                                })}
                            </Select>
                        </FormControl>
                    )
                })}
                <hr/>
            </div>

                <FormControl style={{ width: '49%', margin: "0.3rem" }}>
                        <InputLabel id="demo-simple-select-label-art">{props.t('integration_gender')}:*</InputLabel>
                        <Select id="integration_gender"
                        className={props.theme+"_accent "+props.theme+"_text _100perc"}
                        labelId="demo-simple-select-label-art" 
                        label={props.t('integration_gender')} 
                        value={gender} 
                        onChange={(e) => {setGender(e.target.value)}} 
                        style={{ maxHeight: 43 }}
                        renderValue={(selected) => props.t(selected)}>
                            {integrationGenders.map((row, index) => {
                                return (
                                    <MenuItem value={row} name={row} key={index}>
                                        <ListItemText
                                            primary={props.t(row)}
                                        />
                                    </MenuItem>)
                            })}
                        </Select>
                </FormControl>
                <FormControl style={{ width: '49%', margin: "0.3rem" }}>
                    <InputLabel id="demo-simple-select-label-art">{props.t('integration_gender')}:</InputLabel>
                    <Select id="integration_gender_select"
                    className={props.theme+"_accent "+props.theme+"_text _100perc"}
                    labelId="demo-simple-select-label-art" 
                    label={props.t('integration_gender_select')} 
                    value={genderCode} 
                    onChange={(e) => {
                        setGenderCode(e.target.value);
                        clearSelectedGenderCodes();
                        fetchCodes(codes?.find(code => code.id === e.target.value)?.code, "gender");
                    }} 
                    style={{ maxHeight: 43 }}
                    renderValue={(selected) => {
                        if (!selected)
                            return "";
                        const selectedOption = codes.find(option => option.id == selected)?.value;
                        return selectedOption ?? '';
                    }}>
                        {codes.map((row, index) => {
                            return (
                                <MenuItem value={row.id} name={row.id} key={index}>
                                <ListItemText
                                    primary={props.t(row.value)}
                                />
                            </MenuItem>)
                        })}
                    </Select>
                </FormControl>

                {integrationGenderCodes.map((row, index) => {
                    // get the inputvalue of the other integrationGenderCodes set them to 1 array without duplicates
                    const getOtherSelectedValues = (currentRowId) => {
                        return [...new Set(
                            integrationGenderCodes
                                .filter(code => code.id !== currentRowId)
                                .map(code => code.inputValue)
                                .flat()
                        )];
                    };
                    let otherSelectedValues = getOtherSelectedValues(row.id);
                    return (
                        <FormControl key={row.id} style={{ width: '49%', margin: "0.3rem" }}>
                            <InputLabel id="demo-simple-select-label-art">{props.t(row.value)}</InputLabel>
                            <Select  id={row.id}
                            className={props.theme+"_accent "+props.theme+"_text _100perc"}
                            labelId="demo-simple-select-label-art" 
                            label={props.t(row.value)}
                            value={row.inputValue}
                            disabled={!genderCode}
                            onChange={(event)=>{row.onChange(event.target.value)}}
                            style={{ maxHeight: 43 }}
                            multiple
                            renderValue={(selected) => {
                                if (!selected)
                                    return "";
                                return selected?.sort((a, b) => a.localeCompare(b))?.join(",");
                            }}>
                                {genderGroupCodes.filter(genderCode => {
                                    return !otherSelectedValues?.includes(genderCode?.totcode);
                                })
                                ?.map((genderCodeRow, index) => {      
                                    return (
                                        <MenuItem value={genderCodeRow.totcode} name={genderCodeRow.codeoms} key={index}>
                                            <ListItemText
                                                primary={props.t(genderCodeRow.totcode)}
                                                secondary={props.t(genderCodeRow.codeoms)}
                                            />
                                        </MenuItem>
                                        )
                                })}
                            </Select>
                        </FormControl>
                    )
                })}
            </span> 
        )
    }

    const [articlesLimit, setArticlesLimit] = useState("");
    const [ediArticlesOnly, setEdiArticlesOnly] = useState(false);
    const limits = ["Unlimited","100", "200", "300", "400", "500", "600", "700", "800", "900", "1000"];
    const [customLimit, setCustomLimit] = useState('');
    const handleCustomInputChange = (event) => {
        setCustomLimit(event.target.value);
        setArticlesLimit(event.target.value);
    };
    const showArticleFilters = (integration) => {
        return (
        <div>
            <FormControl key={'articles_limit'} style={{ width: '49%', margin: "0.3rem" }}>
                <InputLabel id="demo-simple-select-label-art">{props.t("articles_limit")}</InputLabel>
                <Select  id={'articles_limit_select'}
                className={props.theme+"_accent "+props.theme+"_text _100perc"}
                labelId="demo-simple-select-label-art" 
                label={props.t("articles_limit")}
                value={articlesLimit}
                onChange={(event)=>{setArticlesLimit(event.target.value)}}
                style={{ maxHeight: 43 }}
                renderValue={(selected) => selected}>
                    {limits.map((limit, index) => (
                        <MenuItem value={limit} name={limit} key={index}>
                            <ListItemText primary={limit} />
                        </MenuItem>
                    ))}
                    <MenuItem value="custom">
                        <TextField
                            placeholder="Custom"
                            value={customLimit}
                            onChange={handleCustomInputChange}
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={(e) => e.stopPropagation()} // Prevent Select's default behavior on keydown // Prevent closing the select dropdown when typing
                            fullWidth
                        />
                    </MenuItem>
                </Select>
            </FormControl>
            <div key={'increased_shipping'} style={{ margin: "0.3rem",  display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'center'}} className='align_right'>
                {props.t('only_EDI_articles')}
                <Switch
                    checked={ediArticlesOnly}
                    onChange={()=>{setEdiArticlesOnly(!ediArticlesOnly);}}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
               
                { (integration !== "facebook") ? (  <> 
                {props.t('increased_shipping')}
                <Switch
                    checked={chkIncreasedShipping}
                    className='align_right'
                    style={{marginRight: 10}}
                    onChange={()=>{setChkIncreasedShipping(!chkIncreasedShipping);}}
                    inputProps={{ 'aria-label': 'controlled' }}
                    />
                
                </> ) : ''}
            </div>

        { (integration !== "facebook") ? (
            <div key={'increased_shipping_details'} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'flex', justifyContent: 'flex-start'}}>
            <TextField
                autoComplete="off" 
                className={props.theme+"_accent "+props.theme+"_text _100perc"}                
                style={{width: '49%', margin: "0.3rem"}}
                id="free_shipping_from"
                label={props.t('free_shipping_from')}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                    },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                    }} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }}}
                value={freeShippingFrom}
                onChange={(event)=>{setFreeShippingFrom(event.target.value)}}
                />

            { (chkIncreasedShipping && integration !== "facebook") ? (
                <>
                        <TextField
                        autoComplete="off" 
                        className={props.theme+"_accent "+props.theme+"_text _100perc"}                
                        style={{width: '24%', margin: "0.3rem"}}
                        hidden={!chkIncreasedShipping}
                        id="increased_shipping_from"
                        label={props.t('increased_shipping_from')}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                            }} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }}}
                        value={increasedShippingFrom}
                        onChange={(event)=>{setIncreasedShippingFrom(event.target.value)}}
                        />
        
                    <TextField
                        autoComplete="off" 
                        className={props.theme+"_accent "+props.theme+"_text _100perc"}                
                        style={{width: '24%', margin: "0.3rem"}}
                        id="increased_shipping"
                        label={props.t('increased_shipping_amount')}
                        sx={(props.theme==='light') ? {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.light_text
                            },
                            '& .MuiInputLabel-root': {
                                color: Colors.light_text
                            }} 
                            :
                            {
                            '& .MuiOutlinedInput-input': {
                                color: Colors.dark_text
                                },
                                '& .MuiInputLabel-root': {
                                    color: Colors.dark_text_accent
                                }}}
                        value={increasedShipping}
                        onChange={(event)=>{setIncreasedShipping(event.target.value)}}
                        />
                </>
                ) : '' 
            }
            </div> ) : ''}

            <TextField
                autoComplete="off" 
                className={props.theme+"_accent "+props.theme+"_text _100perc"}
                multiline
                style={{margin: "0.3rem", width: '98.6%'}}
                id="custom_where"
                label={props.t('custom_where')}
                sx={(props.theme==='light') ? {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.light_text
                    },
                    '& .MuiInputLabel-root': {
                        color: Colors.light_text
                    }} 
                    :
                    {
                    '& .MuiOutlinedInput-input': {
                        color: Colors.dark_text
                        },
                        '& .MuiInputLabel-root': {
                            color: Colors.dark_text_accent
                        }}}
                minRows={1}
                value={customWhere}
                onChange={(event)=>{validateCustomWhereInput(event.target.value)}}
                />

            </div>
        )
    }

    return (
        <div className={(props.screenWidth) < 600 ? 'not_expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme : 'expanded container_content _80perc ' + props.theme + ' container_content_' + props.theme}>
            <FloatingBackButton disabled={false} onClick={()=>{navigate("/"+props.lang+"/integrations");}} />
            <FloatingSaveButton disabled={loading} onClick={()=>{handleSubmit();}} />
            <div className={"container_list__title " + props.theme}><h2>{props.integration}</h2></div>
            
            {loading && <div className={"container_list__title " + props.theme} style={{display:"flex", justifyContent: "space-evenly", marginTop: 5, border: 1}}>
                        <TailSpin 
                            height="40"
                            width="40"
                            color={Colors.sft_blue}
                            ariaLabel='loading'
                        />
                    </div>}
            
            {!loading && <div>

                <form>  
                    <div className="container-list-title">
                        <h2>{props.title}</h2>
                        <br/>
                    </div>
                    
                    <fieldset className={(props.theme==='light') ? 'light container_list' : 'dark  container_list'}>
                        <legend className='_100perc'>
                            <span className="number">1</span> {props.t('settings')}
                            <Switch
                            className='align_right'
                            checked={active}
                            onChange={()=>{setActive(!active);}}
                            inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </legend>
                        {showSettings(props.integration)}  
         
                        {listId !== '+' && <div className='_80perc' style={{margin: "0.3rem"}}>
                            <label className='_80perc'>File: <a className='wordbreak' href={getIntegrationFileUrl()}>{getIntegrationFileUrl()}</a></label>  
                        </div>}

                    </fieldset>

                    {repId == "0" && (<div><fieldset>
                        <legend><span className="number">2</span> {props.t('personalisation')}</legend>
                        {showPersonalization()}
                        <hr/>
                        {showIntegrationConfig(props.integration)}
                        <hr/>
                        {showArticleFilters(props.integration)}
                        <hr/>
                        <div className='_100perc margin_bottom_5px'>
                            <label htmlFor="integration_title">{props.t('integration_title')}:*</label>
                        </div>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[barbodykleur]", "title");}}>BarbodyColor</Button>

                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[lev_naam]", "title");}}>BrandName</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[lev_webnaam]", "title");}}>BrandWeb</Button>

                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[code1]", "title");}}>Code1</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[code2]", "title");}}>Code2</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[code3]", "title");}}>Code3</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[code4]", "title");}}>Code4</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[code5]", "title");}}>Code5</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[code6]", "title");}}>Code6</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[code7]", "title");}}>Code7</Button>

                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[kleur]", "title");}}>Color</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[kleurlev]", "title");}}>ColorBrand</Button> 

                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[oms1]", "title");}}>Descr1</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[oms2]", "title");}}>Descr2</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[oms3]", "title");}}>Descr3</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[oms4]", "title");}}>Descr4</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[oms5]", "title");}}>Descr5</Button>

                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[levart1]", "title");}}>Levart1</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[levart2]", "title");}}>Levart2</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[levart3]", "title");}}>Levart3</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[levart4]", "title");}}>Levart4</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[levart5]", "title");}}>Levart5</Button>

                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[seizoen]", "title");}}>Season</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[seizoen_code]", "title");}}>SeasonID</Button>
                        
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[web_text]", "title");}}>WebText</Button>

                        <input className={(props.theme==='light') ? 'light_accent_darker' : 'dark_accent_darker'} type="text" onChange={(event)=>{setTitle(event.target.value)}} id="integration_title" name="integration_title" value={title}/>

                        <div className='_100perc margin_bottom_5px'>
                            <label htmlFor="integration_descr">{props.t('integration_descr')}:*</label>
                        </div>
                        
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[barbodykleur]", "description");}}>BarbodyColor</Button>

                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[lev_naam]", "description");}}>BrandName</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[lev_webnaam]", "description");}}>BrandWeb</Button>

                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[code1]", "description");}}>Code1</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[code2]", "description");}}>Code2</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[code3]", "description");}}>Code3</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[code4]", "description");}}>Code4</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[code5]", "description");}}>Code5</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[code6]", "description");}}>Code6</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[code7]", "description");}}>Code7</Button>

                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[kleur]", "description");}}>Color</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[kleurlev]", "description");}}>ColorBrand</Button> 

                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[oms1]", "description");}}>Descr1</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[oms2]", "description");}}>Descr2</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[oms3]", "description");}}>Descr3</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[oms4]", "description");}}>Descr4</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[oms5]", "description");}}>Descr5</Button>

                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[levart1]", "description");}}>Levart1</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[levart2]", "description");}}>Levart2</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[levart3]", "description");}}>Levart3</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[levart4]", "description");}}>Levart4</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[levart5]", "description");}}>Levart5</Button>

                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[seizoen]", "description");}}>Season</Button>
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[seizoen_code]", "description");}}>SeasonID</Button>
                        
                        <Button className="button_container__button_small width_140px margin_left__5px margin_bottom_5px" type="button" onClick={()=>{addToBody("[web_text]", "description");}}>WebText</Button>
                        <input className={(props.theme==='light') ? 'light_accent_darker' : 'dark_accent_darker'} type="text" onChange={(event)=>{setDescription(event.target.value)}} id="integration_descr" name="integration_descr" value={description}/>

                    </fieldset>

                    <fieldset>
                        <legend><span className="number">3</span> {props.t('integration_codes')}</legend>
                        <table style={{width:"40%"}}>
                            <tbody>
                                <tr>
                                    <td style={{width:"90%"}}> Code 1 </td>
                                    <td>
                                        <Switch
                                        checked={code1}
                                        onChange={()=>{setCode1(!code1);}}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width:"90%"}}> Code 2 </td>
                                    <td>
                                        <Switch
                                        checked={code2}
                                        onChange={()=>{setCode2(!code2);}}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width:"90%"}}> Code 3 </td>
                                    <td>
                                        <Switch
                                        checked={code3}
                                        onChange={()=>{setCode3(!code3);}}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width:"90%"}}> Code 4 </td>
                                    <td>
                                        <Switch
                                        checked={code4}
                                        onChange={()=>{setCode4(!code4);}}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width:"90%"}}> Code 5 </td>
                                    <td>
                                        <Switch
                                        checked={code5}
                                        onChange={()=>{setCode5(!code5);}}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width:"90%"}}> Code 6 </td>
                                    <td>
                                        <Switch
                                        checked={code6}
                                        onChange={()=>{setCode6(!code6);}}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width:"90%"}}> Code 7 </td>
                                    <td>
                                        <Switch
                                        checked={code7}
                                        onChange={()=>{setCode7(!code7);}}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <br/><br/>
                        { (listId !== "+")
                            ? <Button className="button_container__button button_100perc" type="button"  onClick={()=>{ createOrUpdateReport(); }}>{ (generateRepId) ? props.t('update_export') : props.t('generate_export')}</Button>
                            : ''
                        }                        
                    </fieldset></div>)}
                </form>
            </div>}
            <ReactTooltip multiline={true} className="toolTipClass"/>           
        </div>
    );
}

export default IntegrationDetailScreen;